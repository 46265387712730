// Zuerst importieren Sie die notwendigen Bibliotheken und Styles.
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'
import '@mdi/font/css/materialdesignicons.css' // Stellen Sie sicher, dass dies die richtige Pfadangabe für Ihre Icon-Bibliothek ist.
import 'vuetify/styles'

// Nun erstellen Sie die Vuetify-Instanz.
export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark', // Standardmäßig dunkles Thema verwenden.
    themes: {
      dark: {
        // Hier können Sie Anpassungen am dunklen Thema vornehmen, z. B.:
        // primary: '#E53935',
        // ...weitere Farbanpassungen...
      },
      // Sie können auch ein helles Thema definieren, wenn benötigt.
      light: {
        // ...
      }
    }
  },
  // Hier können Sie andere Vuetify-Optionen konfigurieren, z. B. globale Optionen, Icons usw.
})
