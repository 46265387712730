<template>
    <v-snackbar v-model="showConsentBanner" elevation="24" color="grey-darken-4" timeout="-1">
        Wir nutzen Cookies und Google Analytics. Sind Sie damit einverstanden? (Sie können diese Entscheidung jederzeit
        widerrufen) <a href="/kontakt">Datenschutzerklärung</a>
        <template v-slot:actions>
            <v-btn color="green" text @click="acceptConsent">Annehmen</v-btn>
            <v-btn color="red" text @click="declineConsent">Ablehnen</v-btn>
        </template>
    </v-snackbar>
</template>
  
<script>
export default {
    name: 'ConsentBanner',
    data() {
        return {
            showConsentBanner: true, // Zeige Banner beim Laden der Komponente
        };
    },
    methods: {
        acceptConsent() {
            this.trackConsent(true);
        },
        declineConsent() {
            this.trackConsent(false);
        },
        trackConsent(consent) {
            this.showConsentBanner = false; // Banner ausblenden
            localStorage.setItem('ga_consent', consent);
        },
        show() {
            this.showConsentBanner = !this.showConsentBanner;
        }

    },
    mounted() {
        // Prüfe, ob eine Zustimmung bereits gegeben wurde
        const consentGiven = localStorage.getItem('ga_consent');
        if (consentGiven !== null) {
            this.showConsentBanner = false; // Wenn bereits gespeichert, Banner nicht zeigen
        }
    }
};
</script>
  