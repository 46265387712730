<template>
  <v-dialog v-model="visible" width="auto" transition="dialog-top-transition">
    <v-card>
      <v-card-title>
        {{ player.display_name }}
        <v-btn icon @click="visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs bg-color="dark" v-model="visible_tab">
        <v-tab :value="0">
          <v-icon size="large">mdi-table-large</v-icon>
        </v-tab>
        <v-tab :value="1">
          <v-icon size="large">mdi-chart-bar-stacked</v-icon>
        </v-tab>
      </v-tabs>
      <div v-if="isBusy" class="text-center py-3">
        <p>Lade Daten...</p>
        <v-progress-circular indeterminate color="primary" model-value="20"></v-progress-circular>
      </div>
      <div class="modal-table-container" v-else>
        <b-table v-if="visible_tab === 0" class="player-modal-table" foot-clone no-footer-sorting striped hover
          :items="playerData" :fields="fields" :dark="isDarkMode" head-variant="dark">
          <template v-slot:cell(Liga)="data">
            <router-link
              :to="{ name: 'liga', params: { bundesland: data.item.Bundesland, season: data.item.saison.replace('/', ''), ligaId: data.item.LigaID } }"
              @click="visible = false">{{
                data.item.Liga }}</router-link>
          </template>
          <template v-slot:foot(saison)>Gesamt</template>
          <template v-slot:foot(Mannschaft)>{{ "" }}</template>
          <template v-slot:foot(Liga)>{{ "" }}</template>
          <template v-slot:foot(Trikot_Nr)>{{ "" }}</template>
          <template v-slot:foot(Spiele)>{{ totalSpiele }}</template>
          <template v-slot:foot(Tore)>{{ totalTore }}</template>
          <template v-slot:foot(Feldtore)>{{ totalFeldtore }}</template>
          <template v-slot:foot(Siebenmeter_Tore)>{{ totalSiebenmeterTore }}</template>
          <template v-slot:foot(Siebenmeter_geworfen)>{{ totalSiebenmeterGeworfen }}</template>
          <template v-slot:foot(Durchschnitt)>{{ totalDurchschnitt }}</template>
          <template v-slot:foot(Verwarnungen)>{{ totalVerwarnungen }}</template>
          <template v-slot:foot(Zeitstrafen)>{{ totalZeitstrafen }}</template>
          <template v-slot:foot(Disqualifikationen)>{{ totalDisqualifikationen }}</template>
        </b-table>
        <v-chart ref="echartsRef" v-if="visible_tab === 1" :option="chartOption" theme="dark"
          :style="'height:' + chart_height + 'px; width:' + chart_width + 'px; min-height: 400px'"></v-chart>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/theme/dark';

export default {
  name: 'PlayerModal',
  props: ['player'],
  components: {
    'v-chart': ECharts
  },
  data() {
    return {
      visible: false,
      fields: [
        { key: 'saison', label: 'Saison' },
        { key: 'Mannschaft', label: 'Mannschaft', class: 'text-left' },
        { key: 'Liga', label: 'Liga', class: 'text-left' },
        { key: 'Trikot_Nr', label: 'TrNr.' },
        { key: 'Spiele', label: 'Spiele' },
        { key: 'Tore', label: 'Tore' },
        { key: 'Feldtore', label: 'Feldtore' },
        { key: 'Siebenmeter_Tore', label: '7m Tore' },
        { key: 'Siebenmeter_geworfen', label: '7m gew.' },
        { key: 'Durchschnitt', label: 'Tore/Spiel' },
        { key: 'Verwarnungen', label: 'Verw.' },
        { key: 'Zeitstrafen', label: '2 Min.' },
        { key: 'Disqualifikationen', label: 'Disq.' }
      ],
      playerData: [],
      visible_tab: 0,
      chart_height: '300px',
      chart_width: '300px',
      isBusy: false
    }
  },
  watch: {
    visible(value) {
      if (!value) this.close();
    },
  },
  computed: {
    isDarkMode: {
      get() {
        return this.$store.state.isDarkMode;
      },
      set() {
        this.$store.commit('toggleDarkMode');
      }
    },
    totalSpiele() {
      return this.playerData.reduce((acc, player) => parseFloat(acc) + parseFloat(player.Spiele), 0);
    },
    totalTore() {
      return this.playerData.reduce((acc, player) => parseFloat(acc) + parseFloat(player.Tore), 0);
    },
    totalFeldtore() {
      return this.playerData.reduce((acc, player) => parseFloat(acc) + parseFloat(player.Feldtore), 0);
    },
    totalSiebenmeterTore() {
      return this.playerData.reduce((acc, player) => parseFloat(acc) + parseFloat(player.Siebenmeter_Tore), 0);
    },
    totalSiebenmeterGeworfen() {
      return this.playerData.reduce((acc, player) => parseFloat(acc) + parseFloat(player.Siebenmeter_geworfen), 0);
    },
    totalVerwarnungen() {
      return this.playerData.reduce((acc, player) => acc + parseFloat(player.Verwarnungen || 0), 0);
    },
    totalZeitstrafen() {
      return this.playerData.reduce((acc, player) => acc + parseFloat(player.Zeitstrafen || 0), 0);
    },
    totalDisqualifikationen() {
      return this.playerData.reduce((acc, player) => acc + parseFloat(player.Disqualifikationen || 0), 0);
    },
    totalSiebenmeterPercentage() {
      const totalTore = this.totalSiebenmeterTore;
      const totalGeworfen = this.totalSiebenmeterGeworfen;
      const percentage = (totalTore / totalGeworfen) * 100
      return percentage.toFixed(2) + "%";
    },
    totalDurchschnitt() {
      return (this.totalTore / this.totalSpiele).toFixed(2);
    },
  },
  methods: {
    close() {
      this.visible = false
      document.body.removeChild(this.$el.parentNode)
    },
    formatSeason(season) {
      return season.slice(0, 2) + '/' + season.slice(2);
    },
    fetchData() {
      this.isBusy = true
      this.visible = true;
      fetch(`${this.$router.options.backendPath}/playerdata.php?name=${this.player.display_name}&bundesland=${this.player.Bundesland}`)
        .then(response => response.json())
        .then(data => {
          let transformedData = [];

          for (let key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key) && Array.isArray(data[key])) {
              data[key].forEach(item => {
                item.Trikot_Nr = parseInt(item.Trikot_Nr);
                item.saison = this.formatSeason(key);
                item.Tore = parseFloat(item.Tore);
                item.Spiele = parseFloat(item.Spiele);
                item.Durchschnitt = parseFloat(item.Durchschnitt);
                item.Siebenmeter_Tore = parseFloat(item.Siebenmeter_Tore);
                item.Siebenmeter_geworfen = parseFloat(item.Siebenmeter_geworfen);
                item.Feldtore = parseFloat(item.Feldtore);
                item.Zeitstrafen = parseFloat(item.Zeitstrafen);
                transformedData.push(item);
              });
            }
          }
          this.playerData = transformedData;
        }).then(() => {
          this.isBusy = false
          this.$nextTick(() => {
            const container = document.querySelector('.modal-table-container');
            if (container) {
              const { width, height } = container.getBoundingClientRect();
              this.chart_width = width;
              this.chart_height = height;
            }
          });
          this.generateChartOptions();
        });
    },
    navigate(ligaId, season) {
      this.$router.push({ name: 'liga', params: { season: season, ligaId: ligaId } });
    },
    generateChartOptions() {
      const aggregatedData = {};

      // Aggregieren Sie die Daten für jede Saison
      this.playerData.forEach(item => {
        if (!aggregatedData[item.saison]) {
          aggregatedData[item.saison] = {
            saison: item.saison,
            Feldtore: 0,
            '7m Tore': 0,
            Spiele: 0,
            Verwarnungen: 0,
            '2 Min.': 0
          };
        }
        aggregatedData[item.saison].Feldtore += parseFloat(item.Feldtore);
        aggregatedData[item.saison]['7m Tore'] += parseFloat(item.Siebenmeter_Tore);
        aggregatedData[item.saison].Spiele += parseFloat(item.Spiele);
        aggregatedData[item.saison].Verwarnungen += parseFloat(item.Verwarnungen);
        aggregatedData[item.saison]['2 Min.'] += parseFloat(item.Zeitstrafen);
      });

      // Konvertieren Sie die aggregierten Daten in ein Array für das Dataset
      const sourceData = Object.values(aggregatedData);

      this.chartOption = {
        legend: {},
        animation: false,
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            saveAsImage: { show: true },
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '10px',
          right: '40px',
          top: '10%',
          bottom: '10px',
          containLabel: true // Diese Option stellt sicher, dass die Beschriftungen nicht abgeschnitten werden
        },
        dataset: {
          dimensions: ['saison', 'Feldtore', '7m Tore', 'Spiele', 'Verwarnungen', '2 Min.'],
          source: sourceData
        },
        xAxis: { type: 'category' },
        yAxis: {},
        series: [
          {
            name: 'Feldtore',
            type: 'bar',
            stack: 'gesamt',
            barGap: '0%',
            label: {
              show: true,
              position: 'inside',
              // formatter: function (params) {
              //   return params.value.Feldtore != 0 ? params.value.Feldtore : '';
              // }
            }
          },
          {
            name: '7m Tore',
            type: 'bar',
            stack: 'gesamt',
            barGap: '0%',
            label: {
              show: true,
              position: 'inside',
              formatter: function (params) {
                return params.value['7m Tore'] != 0 ? params.value['7m Tore'] : '';
              }
            }
          },
          {
            name: 'Spiele',
            type: 'bar',
            barGap: '0%',
            label: {
              show: true,
              position: 'inside',
              // formatter: function (params) {
              //   return params.value.Spiele != 0 ? params.value.Spiele : '';
              // }
            }
          },
          {
            name: 'Verwarnungen',
            type: 'bar',
            barGap: '0%',
            label: {
              show: true,
              position: 'inside',
              // formatter: function (params) {
              //   return params.value.Verwarnungen != 0 ? params.value.Verwarnungen : '';
              // }
            }
          },
          {
            name: '2 Min.',
            type: 'bar',
            barGap: '0%',
            label: {
              show: true,
              position: 'inside',
              // formatter: function (params) {
              //   return params.value['2 Min.'] != 0 ? params.value['2 Min.'] : '';
              // }
            }
          }
        ]
      };
    }
  },
  mounted() {
    this.fetchData()
  },
}
</script>


<style>
.modal-table-container {
  max-width: 100%;
  overflow-x: auto;
}

.v-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v-overlay {
  align-items: flex-start !important;
}

.player-modal-table {
  margin-bottom: 0 !important;
  width: 100%;
}

.player-modal-table {
  max-width: 100%;
  overflow-x: auto;
}

.player-modal-table tr {
  vertical-align: middle;
  text-align: center;
}

.player-modal-table th {
  background-color: #212529 !important;
  color: white !important;
  border: none !important;
  text-align: center !important;
  font-weight: bold !important;
  font-size: 15px !important;
  vertical-align: middle;
}

.player-modal-table tbody td {
  border-right: 1px solid darkgray;
  font-size: 14px;
}

.darkmode .player-modal-table tbody td {
  border-right: 1px solid lightgray;
}

.player-modal-table tfoot th {
  border-right: 1px solid #CCC !important;
}

.player-modal-table thead tr th:nth-child(2),
.player-modal-table tbody tr td:nth-child(2),
.player-modal-table thead tr th:nth-child(3),
.player-modal-table tbody tr td:nth-child(3) {
  text-align: left !important;
}
</style>
