<template>
    <v-banner v-if="showInstallPrompt" app color="info">
        <template v-slot:actions>
            <v-btn icon @click="promptInstall" v-if="!isIOS">
                <v-icon>mdi-download</v-icon>
            </v-btn>
            <!-- <v-btn icon v-if="isIOS" @click="openAppleTutorial">
                <v-icon>mdi-information</v-icon>
            </v-btn> -->
            <v-btn icon @click="dismissBanner">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
        <template v-slot:text>
            <v-img src="/img/icons/apple-touch-icon-180x180.png" class="app-icon" @click="promptInstall" alt="App Icon" />
            <div>
                Auch als App verfügbar.
                <span v-if="isIOS"><br><a class="text-decoration-none" href="https://support.apple.com/de-de/guide/iphone/iph42ab2f3a7/ios#iph4f9a47bbc" target="_blank">HIER</a> klicken für eine Anleitung.</span>
                <span v-else><br>Jetzt installieren</span>
            </div>
        </template>
    </v-banner>
</template>

<script>
export default {
    data() {
        return {
            showInstallPrompt: false,
            deferredPrompt: null,
        };
    },
    computed: {
        isAndroid() {
            return /android|windows/i.test(navigator.userAgent);
        },
        isIOS() {
            console.log(navigator.userAgent)
            return /iphone|ipad|ipod/i.test(navigator.userAgent);
        },
    },
    mounted() {
        this.initializePwaPrompt();
    },
    methods: {
        initializePwaPrompt() {
            if (!localStorage.getItem('pwaBannerDismissed')) {
                if (this.isIOS) {
                    // Auf iOS zeigen wir den Banner immer direkt an
                    this.showInstallPrompt = true;
                } else if (this.isAndroid) {
                    // Nur für Android: Abhören des 'beforeinstallprompt' Events
                    window.addEventListener('beforeinstallprompt', (e) => {
                        e.preventDefault(); // Verhindere das sofortige Anzeigen des A2HS Banners
                        this.deferredPrompt = e; // Speichere das Event für später
                        this.showInstallPrompt = true; // Zeige unseren eigenen Banner an
                    });
                }
            }
        },
        promptInstall() {
            if(this.isIOS)return;
            if (this.deferredPrompt) {
                // Zeige das A2HS Popup für Android
                this.deferredPrompt.prompt();
                this.deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the A2HS prompt');
                    } else {
                        console.log('User dismissed the A2HS prompt');
                    }
                    this.deferredPrompt = null;
                });
            }
            this.showInstallPrompt = false;
        },
        dismissBanner() {
            this.showInstallPrompt = false;
            localStorage.setItem('pwaBannerDismissed', 'true');
        },
        openAppleTutorial() {
            // Öffne die Apple Support Seite in einem neuen Tab
            window.open('https://support.apple.com/de-de/guide/iphone/iph42ab2f3a7/ios#iph4f9a47bbc', '_blank');
        },
    },
};
</script>
  
    
<style>
@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.v-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1301;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    animation: slideUp 0.5s ease-out;
    padding-top: 16px !important;
}

.v-banner .v-btn {
    margin: 0;
    /* Entfernt jeglichen Standardabstand, den Vuetify hinzufügt */
}

.v-banner-actions {
    display: flex;
    align-items: center;
    /* Diese Zeile stellt sicher, dass die Aktionselemente vertikal zentriert sind */
    justify-content: flex-end;
    /* Optional: Elemente am Ende des Containers ausrichten */
}

.v-banner-text {
    display: flex;
    align-items: center
}

.app-icon {
    border-radius: 10px;
    width: 50px;
    height: 50px;
    margin-right: 20px;
}
</style>
  