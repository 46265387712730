<template>
    <div>
        <v-navigation-drawer touchless ref="mainDrawerRef" v-model="mainDrawer" hide-overlay app temporary
            location="right" :class="isMobile ? 'below-app-bar-mobile' : 'below-app-bar'" @click.stop>
            <template v-if="isMobile">
                <v-list-item v-for="item in filteredMenuItems" :key="item.text"
                    @click.stop="item.action && item.action()" :title="item.text">
                    <template v-slot:append v-if="item.dropdown">
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-list-item>
            </template>
        </v-navigation-drawer>
        <!-- Hauptnavigation (Bundesländer) -->
        <v-navigation-drawer location="right" ref="bundeslandDrawerRef" v-model="bundeslandDrawer" temporary
            :hide-overlay="isMobile" app
            :class="isMobile ? 'below-app-bar-mobile elevated_drawer' : 'below-app-bar elevated_drawer'" touchless>
            <router-link :to="{ name: 'landing_bl', params: { bundesland: 'SH', season: '2425' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="Schleswig Holstein/Hamburg">
                    </v-list-item>
                </template>
            </router-link>
            <router-link :to="{ name: 'landing_bl', params: { bundesland: 'BW', season: '2425' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="Baden-Württemberg">
                    </v-list-item>
                </template>
            </router-link>
            <router-link :to="{ name: 'landing_bl', params: { bundesland: 'NRW', season: '2425' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="Nordrhein-Westfalen">
                    </v-list-item>
                </template>
            </router-link>
            <router-link :to="{ name: 'landing_bl', params: { bundesland: 'RP', season: '2425' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="Rheinland-Pfalz/Saarland">
                    </v-list-item>
                </template>
            </router-link>
        </v-navigation-drawer>
        <!-- Hauptnavigation (Saisons) -->
        <v-navigation-drawer touchless location="right" ref="saisonDrawerRef" v-if="isBundeslandDefined"
            v-model="saisonDrawer" temporary :hide-overlay="isMobile" app
            :class="isMobile ? 'below-app-bar-mobile elevated_drawer' : 'below-app-bar elevated_drawer'">
            <!-- 2024/2025 Season -->
            <router-link
                :to="{ name: 'landing_bl', params: { bundesland: this.$route.params.bundesland, season: '2425' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="2024/2025">
                    </v-list-item>
                </template>
            </router-link>
            <!-- 2023/2024 Season -->
            <router-link
                :to="{ name: 'landing_bl', params: { bundesland: this.$route.params.bundesland, season: '2324' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="2023/2024">
                    </v-list-item>
                </template>
            </router-link>
            <!-- 2022/2023 Season -->
            <router-link
                :to="{ name: 'landing_bl', params: { bundesland: this.$route.params.bundesland, season: '2223' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="2022/2023">
                    </v-list-item>
                </template>
            </router-link>
            <!-- 2021/2022 Season -->
            <router-link
                :to="{ name: 'landing_bl', params: { bundesland: this.$route.params.bundesland, season: '2122' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="2021/2022">
                    </v-list-item>
                </template>
            </router-link>
            <!-- 2020/2021 Season -->
            <router-link
                :to="{ name: 'landing_bl', params: { bundesland: this.$route.params.bundesland, season: '2021' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="2020/2021">
                    </v-list-item>
                </template>
            </router-link>
            <!-- 2019/2020 Season -->
            <router-link
                :to="{ name: 'landing_bl', params: { bundesland: this.$route.params.bundesland, season: '1920' } }">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="2019/2020">
                    </v-list-item>
                </template>
            </router-link>
            <!-- 2018/2019 Season -->
            <router-link
                :to="{ name: 'landing_bl', params: { bundesland: this.$route.params.bundesland, season: '1819' } }"
                v-if="this.$route.params.bundesland != 'NRW'">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="2018/2019">
                    </v-list-item>
                </template>
            </router-link>
            <!-- 2017/2018 Season -->
            <router-link
                :to="{ name: 'landing_bl', params: { bundesland: this.$route.params.bundesland, season: '1718' } }"
                v-if="this.$route.params.bundesland != 'NRW'">
                <template v-slot:default="{ navigate }">
                    <v-list-item @click="navigate" title="2017/2018">
                    </v-list-item>
                </template>
            </router-link>
        </v-navigation-drawer>
        <!-- Hauptnavigation (Bereiche) -->
        <v-navigation-drawer touchless location="right" ref="bereichDrawerRef" v-model="bereichDrawer" temporary
            :hide-overlay="isMobile" app
            :class="isMobile ? 'below-app-bar-mobile elevated_drawer' : 'below-app-bar elevated_drawer'">
            <v-list-item @click.stop="handleOverallClick(overallItems)" title="Gesamt">
                <template v-slot:append v-if="overallItems.length > 1">
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-list-item>
            <v-list-item v-for="bereich in bereichsList" :key="bereich.name" @click.stop="openLigenDrawer(bereich)"
                :title="bereich.display_name ? bereich.display_name : bereich.name">
                <template v-slot:append>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-list-item>
        </v-navigation-drawer>
        <!-- Unter-Drawer für Ligen -->
        <v-navigation-drawer touchless location="right" ref="ligenDrawerRef" v-model="ligenDrawer" temporary
            :hide-overlay="isMobile" app
            :class="isMobile ? 'below-app-bar-mobile elevated_drawer' : 'below-app-bar elevated_drawer'" @click.stop>
            <v-list-item v-if="currentMenu && !isMobile" :title="currentMenu" prepend-icon="mdi-chevron-left"
                @click="closeSubMenu">
            </v-list-item>
            <v-list-item v-for=" liga in ligenItems " :key="liga.text" @click="liga.action" :title="liga.text">
            </v-list-item>
        </v-navigation-drawer>

        <v-app-bar app clipped-left>
            <template v-if="currentMenu && isMobile">
                <v-btn prepend-icon="mdi-chevron-left" class="menuBackBtn" @click="closeSubMenu">{{ currentMenu
                    }}</v-btn>
            </template>
            <template v-else>
                <v-img :src="require('@/assets/images/logo_colored.svg')" contain max-height="50" max-width="50"
                    class="cursor-pointer me-5" @click.stop="$router.push({ name: 'index' });"></v-img>
            </template>

            <v-text-field v-if="!currentMenu" ref="searchField" hide-details class="search-field" clearable
                prepend-inner-icon="mdi-magnify" single-line v-model="searchText" :loading="searchLoading"
                variant="underlined">
            </v-text-field>

            <v-spacer></v-spacer>

            <!-- Mobile Navigation -->
            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn v-for="item in filteredMenuItems" :key="item.text" @click.stop="item.action">
                    {{ item.text }}
                    <v-icon v-if="item.dropdown" location="right">mdi-chevron-down</v-icon>
                </v-btn>
            </v-toolbar-items>
            <v-btn icon @click="isDarkMode = !isDarkMode">
                <v-icon>{{ isDarkMode ? 'mdi-brightness-5' : 'mdi-brightness-2' }}</v-icon>
            </v-btn>
            <v-btn icon class="hidden-md-and-up" @click.stop="toggleDrawers">
                <v-icon>{{ mainDrawer || bereichDrawer || ligenDrawer || saisonDrawer || bundeslandDrawer ? 'mdi-close'
                    :
                    'mdi-menu' }}</v-icon>
            </v-btn>
        </v-app-bar>
        <v-list v-if="searchResults.length > 0 && searchText" ref="searchDropdown"
            class="position-absolute search-dropdown" :style="{ left: searchDropdownLeft, top: searchDropdownTop }">
            <v-list-item v-for="result in searchResults" :key="result" @click="openPlayerModal(result)">
                <v-list-item-title>{{ result.display_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ result.Mannschaft }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ result.Liga }}</v-list-item-subtitle>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    data() {
        return {
            mainDrawer: false,
            bereichDrawer: false,
            saisonDrawer: false,
            bundeslandDrawer: false,
            ligenDrawer: false,
            currentMenu: null,
            drawerHistory: [],
            ligenItems: [],
            menuItems: [
                {
                    text: 'Startseite',
                    action: () => {
                        if (this.$router.currentRoute.name !== 'index') {
                            this.$router.push({ name: 'index' });
                        }
                    }
                },
                {
                    text: 'Ligen',
                    action: () => {
                        if (!this.isMobile) this.closeAllDrawers();
                        this.bereichDrawer = true
                        this.currentMenu = 'Bereiche';
                        this.drawerHistory.push('bereichDrawer');
                    },
                    dropdown: true
                },
                {
                    text: 'Saisons',
                    action: () => {
                        if (!this.isMobile) this.closeAllDrawers();
                        this.saisonDrawer = true
                        this.currentMenu = 'Saisons'
                        this.drawerHistory.push('saisonDrawer');
                    },
                    dropdown: true
                },
                {
                    text: 'Bundesländer',
                    action: () => {
                        if (!this.isMobile) this.closeAllDrawers();
                        this.bundeslandDrawer = true
                        this.currentMenu = 'Bundesländer'
                        this.drawerHistory.push('bundeslandDrawer');
                    },
                    dropdown: true
                },
                {
                    text: 'Kontakt',
                    action: () => {
                        if (this.$router.currentRoute.name !== 'kontakt') {
                            this.$router.push({ name: 'kontakt' });
                        }
                    }
                },
            ],
            bereiche: {},
            searchText: "",
            searchLoading: false,
            searchMenuVisible: false,
            searchResults: [],
            selectedPlayer: null,
            searchAttempted: false,
            searchDropdownLeft: '0px',
            searchDropdownTop: '0px',
        };

    },
    watch: {
        '$route'() {
            this.updateBereiche();
        },
        searchText(newQuery) {
            // Debounce der Eingabe, um übermäßige Anfragen zu vermeiden.
            this.debouncedSearch(newQuery);
        },
    },
    computed: {
        isDarkMode: {
            get() {
                return this.$store.state.isDarkMode;
            },
            set() {
                this.$store.commit('toggleDarkMode');
            }
        },
        isMobile() {
            return this.$vuetify.display.smAndDown;
        },
        bereichsList() {
            return Object.keys(this.bereiche).map(name => {
                const filteredLigen = this.bereiche[name].ligen.filter(liga => liga[this.$route.params.season] != null);
                return {
                    name: this.bereiche[name].name,
                    ligen: filteredLigen,
                    order: this.bereiche[name].order,
                    display_name: this.bereiche[name].display_name
                };
            }).filter(bereich => bereich.ligen.length > 0);
        },
        isBundeslandDefined() {
            return this.$route.params.bundesland !== undefined && this.$route.params.bundesland !== null && this.$route.params.bundesland !== '';
        },
        filteredMenuItems() {
            return this.menuItems.filter(item => {
                if (item.text === 'Saisons' || item.text === 'Ligen') {
                    return this.isBundeslandDefined;
                }
                return true;
            });
        },
        overallItems() {
            const bundesland = this.$route.params.bundesland
            const overallItems = []
            if (bundesland === 'SH' || bundesland === 'HH') {
                overallItems.push({ name: 'Schleswig-Holstein', code: 'SH' }, { name: 'Hamburg', code: 'HH' });
            } else if (bundesland === 'RP' || bundesland === 'SL') {
                overallItems.push({ name: 'Rheinland-Pfalz', code: 'RP' }, { name: 'Saarland', code: 'SL' });
            } else {
                overallItems.push({ code: bundesland });
            }
            return overallItems
        }
    },
    mounted() {
        if (localStorage.getItem('darkMode') !== null) {
            this.darkMode = JSON.parse(localStorage.getItem('darkMode'));
        }
        this.$nextTick(() => {
            window.addEventListener('click', this.handleClickOutside);
            window.addEventListener('resize', this.updateSearchDropdownPosition);
            this.updateSearchDropdownPosition();
        });
    },
    created() {
        this.debouncedSearch = _.debounce(this.fetchSearchResults, 500); // Wartezeit 
    },
    beforeUnmount() {
        window.removeEventListener('click', this.handleClickOutside);
        window.removeEventListener('resize', this.updateSearchDropdownPosition);
    },
    methods: {
        updateBereiche() {
            if (!this.$route.params.bundesland) return

            let bereiche
            if (this.$route.params.bundesland === 'SH' || this.$route.params.bundesland === 'HH') {
                bereiche = this.combineLigen(this.$store.state.ligenJSON, 'SH', 'HH');
            } else if (this.$route.params.bundesland === 'RP' || this.$route.params.bundesland === 'SL') {
                bereiche = this.combineLigen(this.$store.state.ligenJSON, 'RP', 'SL');
            } else {
                bereiche = this.$store.state.ligenJSON[this.$route.params.bundesland];
            }
            this.bereiche = Object.keys(bereiche).map(name => {
                return {
                    name: name,
                    ligen: bereiche[name].Ligen,
                    order: bereiche[name].order,
                    display_name: bereiche[name].display_name
                };
            }).sort((a, b) => a.order - b.order);
        },
        combineLigen(data, land1, land2) {
            const combinedLigen = { ...data[land1], ...data[land2] };
            for (let bereich in combinedLigen) {
                if (data[land1][bereich] && data[land2][bereich]) {
                    combinedLigen[bereich].Ligen = [
                        ...data[land1][bereich].Ligen,
                        ...data[land2][bereich].Ligen
                    ];
                }
            }
            return combinedLigen;
        },
        handleClickOutside(event) {
            const searchFieldElement = this.$refs.searchField?.$el;
            const dropdownElement = this.$refs.searchDropdown?.$el; // Stellen Sie sicher, dass Sie das Ref im Dropdown hinzufügen.

            // Überprüfen, ob der Klick außerhalb des Suchfelds und des Dropdown-Menüs erfolgt ist
            if (searchFieldElement && dropdownElement &&
                !searchFieldElement.contains(event.target) &&
                !dropdownElement.contains(event.target)) {
                this.searchText = ''; // Suchfeld leeren
                this.searchResults = []; // Dropdown verbergen
            }

            if (document.querySelector('.menuBackBtn')?.contains(event.target) || event.target.classList.contains('v-navigation-drawer__content')) {
                return;
            }

            this.closeAllDrawers();
        },
        handleOverallClick(items) {
            if (items.length === 1) {
                this.$router.push({ name: 'bl_overall', bundesland: items[0].code, saison: this.$route.params.season });
            } else {
                this.ligenItems = items.map(item => ({
                    text: item.name,
                    action: () => {
                        this.closeAllDrawers();
                        this.$router.push({ name: 'bl_overall', bundesland: items.code, saison: this.$route.params.season });
                    }
                }));
                this.currentMenu = "Gesamt"
                this.drawerHistory.push('ligenDrawer');
                this.ligenDrawer = true;
                setTimeout(() => {
                    this.bereichDrawer = false;
                }, 150);
            }
        },
        openLigenDrawer(bereich) {
            this.ligenItems = bereich.ligen
                .filter(liga => {
                    // Überprüfe, ob die aktuelle Saison in der Liga existiert und nicht null ist
                    const seasonData = liga[this.$route.params.season];
                    return seasonData && (typeof seasonData === 'string' || (typeof seasonData === 'object' && seasonData.id));
                })
                .map(liga => {
                    const seasonData = liga[this.$route.params.season];
                    // Wenn die Saison ein Objekt ist, nimm die ID und den Namen, sonst verwende den Standardwert
                    const ligaId = typeof seasonData === 'object' ? seasonData.id : seasonData;
                    const ligaName = typeof seasonData === 'object' ? seasonData.name : liga.Liganame;

                    return {
                        text: ligaName,
                        action: () => {
                            this.closeAllDrawers();
                            this.navigate('liga', ligaId);
                        }
                    };
                });

            this.currentMenu = bereich.display_name || bereich.name;
            this.drawerHistory.push('ligenDrawer');
            this.ligenDrawer = true;

            setTimeout(() => {
                this.bereichDrawer = false;
            }, 150);
        },
        navigate(route, ligaID = null) {
            const targetLocation = {
                name: route
            };
            // Wenn ein ligaID/ligaId übergeben wurde, füge ihn den Parametern hinzu
            if (ligaID) {
                targetLocation.params = {
                    season: this.$route.params.season,
                    ligaId: ligaID
                };
            }
            this.$router.push(targetLocation);
            // // Aktuelle Route und Parameter überprüfen
            // const currentLocation = this.$router.currentRoute;
            // // Prüfen, ob es Ziel-Parameter gibt und diese mit der aktuellen Route vergleichen

            // const isSameLocation = targetLocation.params
            //     ? currentLocation.name === targetLocation.name &&
            //     currentLocation.params.season === targetLocation.params.season &&
            //     currentLocation.params.ligaId === targetLocation.params.ligaId
            //     : currentLocation.name === targetLocation.name;
            // if (isSameLocation) {
            //     return; // Beendet die Funktion frühzeitig
            // }
        },
        closeSubMenu() {
            if (this.drawerHistory.length > 0) {
                const lastDrawer = this.drawerHistory.pop();
                if (lastDrawer === 'ligenDrawer') {
                    this.currentMenu = 'Bereiche';
                    this.ligenDrawer = false
                    setTimeout(() => {
                        this.bereichDrawer = true;
                    }, 0);
                } else if (lastDrawer === 'bereichDrawer') {
                    setTimeout(() => {
                        this.mainDrawer = true;
                    }, 0);
                    this.currentMenu = null;
                    this.bereichDrawer = false;
                } else if (lastDrawer === 'saisonDrawer') {
                    setTimeout(() => {
                        this.mainDrawer = true;
                    }, 0);
                    this.currentMenu = null;
                    this.saisonDrawer = false;
                } else if (lastDrawer === 'bundeslandDrawer') {
                    setTimeout(() => {
                        this.mainDrawer = true;
                    }, 0);
                    this.currentMenu = null;
                    this.bundeslandDrawer = false;
                }
            } else {
                this.ligenDrawer = false;
                this.bereichDrawer = false;
                this.bundeslandDrawer = false;
                this.saisonDrawer = false;
                this.currentMenu = null;
            }
        },
        toggleDrawers() {
            if (this.mainDrawer || this.bereichDrawer || this.ligenDrawer) {
                this.closeAllDrawers();
                this.drawerHistory = []; // Verlauf leeren, wenn alle Drawers geschlossen sind
            } else {
                this.drawerHistory.push('mainDrawer');
                this.mainDrawer = true;
            }
        },
        closeAllDrawers() {
            this.mainDrawer = false;
            this.bereichDrawer = false;
            this.ligenDrawer = false;
            this.saisonDrawer = false;
            this.bundeslandDrawer = false;
            this.currentMenu = null;
        },
        async fetchSearchResults(query) {
            if (!query) {
                this.searchResults = [];
                this.searchAttempted = false;  // Setzen Sie dies zurück, wenn das Suchfeld geleert wird.
                return;
            }

            this.searchLoading = true;
            try {
                // Hier rufen Sie Ihre PHP-API mit dem Suchbegriff auf.
                const season = this.$route.params.season ? this.$route.params.season : '2425';
                const response = await fetch(`${this.$router.options.backendPath}/search.php?season=${season}&searchQuery=${query}`);
                if (!response.ok) {
                    throw new Error('Server response error');
                }

                const results = await response.json();

                this.searchResults = results;
                this.updateSearchDropdownPosition()
            } catch (error) {
                console.error("An error occurred while fetching search results:", error);
                this.searchResults = []; // Sie könnten hier auch eine Fehlerbehandlung hinzufügen.
            } finally {
                this.searchLoading = false;
                this.searchAttempted = true;
            }
        },
        clearSearch() {
            this.searchText = '';
            this.searchResults = [];
            this.searchMenuVisible = false;
        },
        openPlayerModal(player) {
            this.clearSearch()
            this.$playerModal(player);
        },
        updateSearchDropdownPosition() {
            const searchFieldElement = this.$refs.searchField.$el;
            if (searchFieldElement) {
                const rect = searchFieldElement.getBoundingClientRect();
                this.searchDropdownLeft = `${rect.left}px`;
                this.searchDropdownTop = `${rect.bottom}px`; // Position direkt unter dem Suchfeld
            }
        }
    }
};
</script>
<style scoped>
.v-toolbar {
    z-index: 1300 !important;
    padding-left: 13px;
}

.elevated_drawer {
    z-index: 1100 !important;
}

.below-app-bar {
    top: 64px !important;
    width: 33% !important;
}

.below-app-bar-mobile {
    width: 100% !important;
    top: 64px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.v-navigation-drawer__content a {
    text-decoration: none;
    color: white;
}

.search-field {
    max-width: 180px;
    /* oder jede andere Breite, die zu Ihrem Design passt */
}

[role="listbox"]:has(> .d-none) {
    display: none;
}

.search-dropdown {
    background: #1e1e1e;
    max-height: 300px;
    min-width: 180px;
    z-index: 10;
}
</style>
