<template>
    <v-app :class="'app ' + (isDarkMode ? 'darkmode' : 'lightmode')">
      <NavBar></NavBar>
      <v-main>
        <router-view />
        <PWABanner></PWABanner>
      </v-main>
      <v-btn size="small" icon class="consent-button" @click="showBanner">
        <v-icon>mdi-help</v-icon> <!-- Beispiel-Icon -->
      </v-btn>
      <ConsentBanner ref="consentBanner" />
    </v-app>
  </template>
  
  <script>
  import NavBar from '@/components/NavBar.vue';
  import PWABanner from '@/components/PWABanner.vue';
  import ConsentBanner from '@/components/ConsentBanner.vue';
  
  export default {
    name: 'DefaultLayout',
    components: {
      NavBar,
      PWABanner,
      ConsentBanner
    },
    data: () => ({
      //
    }),
    computed: {
      isDarkMode: {
        get() {
          return this.$store.state.isDarkMode;
        },
        set() {
          this.$store.commit('toggleDarkMode');
        }
      }
    },
    methods: {
      showBanner() {
        this.$refs.consentBanner.show(); // Methode in ConsentBanner, um den Banner anzuzeigen
      }
    },
    mounted() {
      // Diese Funktion setzt die --vh Custom Property auf die aktuelle Viewport-Höhe
      const adjustHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      };
  
      // Fügen Sie den Event-Listener hinzu und führen Sie die Funktion sofort aus
      window.addEventListener('resize', adjustHeight);
      adjustHeight(); // Setzen der Variable beim ersten Laden
  
      // Speichern Sie die Funktion im Instanzbereich, damit sie später entfernt werden kann
      this.adjustHeight = adjustHeight;
    }
  }
  </script>
  <style>
  html {
    overflow: hidden !important;
  }
  
  .consent-button {
    position: fixed;
    bottom: 5px;
    left: 4px;
    z-index: 10;
    border: 1.5px solid #2a67fc;
  }
  </style>
  