<template>
  <component :is="layout">
    
  </component>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import MinimalLayout from '@/layouts/MinimalLayout.vue'

export default {
  computed: {
    layout() {
      // Bestimmt das Layout basierend auf der aktuellen Route
      const layout = this.$route.meta.layout
      if (layout === 'minimal') {
        return MinimalLayout
      }
      return DefaultLayout
    }
  }
}
</script>
