import { createStore } from 'vuex'

export default createStore({
  state: {
    isDarkMode: JSON.parse(localStorage.getItem('darkMode') || 'false'),
    leagueData: [],
    teams : null,
    bereiche : null,
    ligenJSON : null
  },
  getters: {
  },
  mutations: {
    toggleDarkMode(state) {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem('darkMode', JSON.stringify(state.isDarkMode));
    },
    setleagueData(state, data) {
      state.leagueData = data;
    },
    setTeams(state, data) {
      state.teams = data;
    },
    setBereiche(state, data) {
      state.bereiche = data;
    },
    setLigenJSON(state, data) {
      state.ligenJSON = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
